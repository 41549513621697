import { DateTime } from 'luxon';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import { useRecoilValue } from 'recoil';

import { navMenuList } from './util';
import { BetaIconComponent, dropdownIcon, newIconDefault, sidebarToggleIcon } from 'assets/image';
import sweetAlert from 'components/common/Alert/sweetAlert';

import { isLoggedInVar } from 'lib/apollo/apollo';
import { showLogin } from 'store/Member';
import { isNavOpen } from 'store/navigationState';

import usePageHistory from 'hooks/Common/usePageHistory';

import { userPlanSelector } from 'atom/user';
import { NewServiceIcon } from 'assets/images';
import PlanIcon from './icon/PlanIcon';

interface SidebarProps {
  isopen?: string;
  isLoggedIn?: boolean;
  isDashboard?: boolean;
}

const Sidebar = styled.div<SidebarProps>`
  height: 100vh;
  position: fixed;
  align-self: auto;
  top: 90px;
  z-index: 50;
  min-width: ${({ isopen }) => (isopen ? '270px !important' : '84px !important')};
  max-width: ${({ isopen }) => (isopen ? '270px !important' : '84px !important')};
  background-color: ${({ theme }) => theme.Components.LogisticNav.bg_main};
  color: ${({ theme }) => theme.Components.LogisticNav.font_main};
  border-right: 1px solid ${({ theme }) => theme.Components.LogisticNav.border_main};
  transition-duration: 300ms;
  overflow-y: auto;
  -ms-overflow-style: none;
  transform-origin: left;
  padding: 27px 14px;
  ::-webkit-scrollbar {
    display: none; /*  Chrome , Safari , Opera */
  }
`;

const ToggleBtn = styled.img`
  cursor: pointer;
  padding: 18px 17px;
  background-color: ${({ theme }) => theme.Primary.bg_primary};

  transform: rotate(180deg);
  place-self: end;
  &.closed {
    transform: rotate(360deg);
  }
`;

const MenuList = styled.ul`
  margin-bottom: 95px;
`;

const MenuWrapper = styled.li<SidebarProps>`
  transition: background-color 200ms ease-in;
`;

const Menu = styled.button`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 13px;
  &:hover {
    color: #000;
    background-color: ${({ theme }) => theme.Primary.hover_gray};
  }
`;

const MenuLink = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 13px 13px;
  cursor: pointer;
  &:hover {
    color: #000;

    background-color: ${({ theme }) => theme.Primary.hover_gray};
    border-radius: 6px;
  }
`;

const MenuContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 30px;

  div.box-menu-icon {
    width: 28px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MenuTitle = styled.span<{ iscurrent?: string }>`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.21428571;
  letter-spacing: -0.02em;
  white-space: nowrap;
  transform-origin: left;
  transition-duration: 200ms;
  font-weight: ${({ theme, iscurrent }) => (iscurrent ? theme.FontWegiht.extra_bold : theme.FontWegiht.medium)};
  display: flex;
  align-items: center;

  &.hidden {
    display: none;
  }

  img.new-icon {
    width: 16px;
    margin-left: 10px;
  }
`;

const Line = styled.div`
  margin: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.Primary.border_primary};

  &.line-hidden {
    border-bottom: 1px solid ${({ theme }) => theme.Primary.border_primary};
  }
`;

const DropdownIcon = styled.img`
  display: flex;
  justify-self: flex-end;
`;

const Submenu = styled.li`
  height: 50px;

  font-size: 14px;
  font-weight: 400;
  line-height: 1.21428571;
  letter-spacing: -0.02em;
  transform-origin: left;
  transition-duration: 200ms;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.Primary.bg_main};
  color: ${({ theme }) => theme.Components.LogisticNav.font_main};
  &:hover {
    color: #000;
    background-color: ${({ theme }) => theme.Primary.hover_bg};
  }
`;

const SubmenuLink = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 62px;
`;

const PlanContainer = styled.div`
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background-color: ${({ theme }) => theme.Components.Navigation.sub_side_nav_bg};
  word-break: keep-all;
  border-radius: 10px;
  padding: 20px 16px;
  border: 0.2px solid ${({ theme }) => theme.Components.Button.UnderBarTab.default_text_color};
  h4 {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.21428571;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.Components.Button.UnderBarTab.default_text_color};
  }

  strong {
    display: flex;
    gap: 5px;
    color: var(--00-d-4-ab, #46b67f);
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.21428571;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.dashboardColors.base};
  }

  p {
    font-size: 12px;
    line-height: 14px; /* 116.667% */
    letter-spacing: -0.24px;

    color: ${({ theme }) => theme.Components.Button.UnderBarTab.default_text_color};
  }
`;

const PlanLink = styled(Link)`
  padding: 7px 10px;
  border: 1px solid ${({ theme }) => theme.dashboardColors.base};
  border-radius: 100px;
  font-size: 12px;
  line-height: 1.16666667;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.dashboardColors.base};
  transition: background-color 150ms ease-in;
`;

const SubmenuContents = styled.div`
  position: relative;
`;

const NewIcon = styled.div`
  position: absolute;
  top: 1.8px;
  left: -20px;

  img {
    width: 16px;
  }
`;

const BetaIcon = styled(BetaIconComponent)<SidebarProps>`
  position: absolute;
  /* right: -20px;
  bottom: 20px; */
  width: 40px;
  height: auto;
  right: ${({ isopen }) => (isopen ? '-40px' : '-20px')};
  bottom: ${({ isopen }) => (isopen ? '20px' : '20px')};
`;

export default function LogisticNavigationAdaptive() {
  const { history, pathName } = usePageHistory();

  const [currentMenu, setCurrentMenu] = useState('');

  const isNavOpenVar = useReactiveVar(isNavOpen);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const userPlan = useRecoilValue(userPlanSelector);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    isNavOpen(true);
  }, [isLoggedIn]);

  const handleNavClick = () => {
    if (isNavOpenVar === true) {
      isNavOpen(false);
    } else {
      isNavOpen(true);
    }
    setCurrentMenu('');
  };

  const handleMenuClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const {
      currentTarget: { value },
    } = event;

    isNavOpen(true);
    setCurrentMenu(prev => {
      if (prev === value) {
        return '';
      }
      return value;
    });
  };

  return (
    <Sidebar
      ref={ref}
      isLoggedIn={isLoggedIn}
      isopen={isNavOpenVar ? isNavOpenVar.toString() : undefined}
      isDashboard={pathName === '/logistic-tools/main' ? true : false}
    >
      <ToggleBtn
        src={sidebarToggleIcon}
        alt="toggle-icon"
        onClick={handleNavClick}
        className={!isNavOpenVar ? 'closed' : ''}
      />
      <MenuList>
        {navMenuList.map((menu, index) => {
          const today = DateTime.now().toMillis();

          const newCategoryDate = menu?.newFeatureDate;

          const newCategory =
            newCategoryDate && DateTime.fromISO(newCategoryDate).plus({ day: 14 }).toMillis() >= today;

          const newFeatureDateList = menu.submenuList?.filter(item => item.newFeatureDate);

          const hasNewFeature =
            newFeatureDateList &&
            newFeatureDateList?.find(
              item =>
                item?.newFeatureDate && DateTime.fromISO(item.newFeatureDate).plus({ day: 14 }).toMillis() >= today,
            );

          return (
            <MenuWrapper key={`nav_${index}_${menu}`} isopen={isNavOpenVar ? isNavOpenVar.toString() : undefined}>
              {menu.url && !menu.submenuList && !menu.comingSoon ? (
                <MenuLink
                  onClick={() => {
                    if (!isLoggedIn) {
                      return showLogin(true);
                    }
                    if (menu.url) {
                      setTimeout(() => {
                        history.push(menu?.url!);
                      }, 100);
                    }
                  }}
                >
                  <MenuContent className="relative">
                    {menu?.isBeta && <BetaIcon isopen={isNavOpenVar ? isNavOpenVar.toString() : undefined} />}
                    <div className="box-menu-icon">
                      {<menu.icon iscurrent={pathName.includes(menu.url) ? 'true' : undefined} />}
                    </div>
                    <MenuTitle
                      iscurrent={pathName.includes(menu.url) ? 'true' : undefined}
                      //  className={isNavOpenVar ? 'flex items-center' : 'hidden'}
                    >
                      {menu.title}
                      {newCategory && <img src={newIconDefault} alt="new" className="new-icon" />}
                    </MenuTitle>
                  </MenuContent>
                </MenuLink>
              ) : menu.url && menu.comingSoon ? (
                <Menu
                  type="button"
                  onClick={() =>
                    sweetAlert({
                      icon: 'warning',
                      title: '서비스 준비중입니다.',
                      showDeny: false,
                    })
                  }
                >
                  <MenuContent>
                    <div className="box-menu-icon">
                      {<menu.icon iscurrent={pathName.includes(menu.url) ? 'true' : undefined} />}
                      {/* <img src={menu.icon} alt={`category_icon_${menu.title}`} /> */}
                    </div>
                    <MenuTitle className={isNavOpenVar ? '' : 'hidden'}>{menu.title}</MenuTitle>
                  </MenuContent>
                </Menu>
              ) : (
                <Menu type="button" onClick={handleMenuClick} value={menu.title}>
                  <MenuContent>
                    <div className="box-menu-icon">
                      {<menu.icon iscurrent={pathName.includes(menu.url!) ? 'true' : undefined} />}
                      {/* <img src={menu.icon} alt={`category_icon_${menu.title}`} /> */}
                    </div>
                    <MenuTitle
                      iscurrent={pathName.includes(menu.url!) ? 'true' : undefined}
                      // className={isNavOpenVar ? 'flex items-center' : 'hidden'}
                    >
                      {menu.title}
                      {hasNewFeature && <NewServiceIcon style={{ marginLeft: '5px' }} />}
                    </MenuTitle>
                  </MenuContent>
                  {isNavOpenVar && menu.submenuList && currentMenu.includes(menu.title) && (
                    <DropdownIcon src={dropdownIcon} alt="drop-icon-nav" />
                  )}
                </Menu>
              )}
              {menu.gap && <Line className={isNavOpenVar ? '' : 'line-hidden'} />}
              {menu.title !== '홈' && menu.submenuList && currentMenu.includes(menu.title) && isNavOpenVar && (
                <ul className="rounded-[6px]">
                  {menu.submenuList.map((submenu, idx) => {
                    const newFeatureSubDate = submenu?.newFeatureDate;

                    const hasNewFeatureSub =
                      newFeatureSubDate && DateTime.fromISO(newFeatureSubDate).plus({ day: 14 }).toMillis() >= today;
                    return (
                      <Submenu key={`nav_sub_${idx}_${submenu}`}>
                        <SubmenuLink
                          onClick={() => {
                            if (isLoggedIn) {
                              setTimeout(() => {
                                history.push(submenu.url);
                              }, 100);
                            } else {
                              return showLogin(true);
                            }
                          }}
                        >
                          <SubmenuContents>
                            {hasNewFeatureSub && (
                              <NewIcon>
                                <NewServiceIcon />
                              </NewIcon>
                            )}
                            {submenu.title}
                          </SubmenuContents>
                        </SubmenuLink>
                      </Submenu>
                    );
                  })}
                </ul>
              )}
            </MenuWrapper>
          );
        })}
      </MenuList>

      {isNavOpenVar && (
        <PlanContainer>
          <h4>이용 중인 플랜</h4>
          <strong>
            <PlanIcon /> {userPlan?.productAlias} 플랜
          </strong>
          <p>플랜을 업그레이드하고, 고도화된 데이터 분석을 통해서 위험을 사전에 인지하세요!</p>

          <PlanLink to="/plan/explain">플랜 보기</PlanLink>
        </PlanContainer>
      )}
    </Sidebar>
  );
}
