import { gql } from '@apollo/client';

// TODO: 모든 쿼리 분활하기
export const GET_PROFILE = gql`
  query Que_Get_Profile($input: ShowProfileInputDto) {
    showProfile(input: $input) {
      error
      ok
      unreadMessageCount
      user {
        subscription {
          id
          createdAt
          updatedAt
          productAlias # 플랜명
          productId # 플랜 ID
          expiredAt
          transactionNo
          flagForHistoryOfPaidPayment
          isLastFlag # 구독 만료예정 확인
          subCategory # 연간/월간
          numberOfMember
          content {
            weeklyReport {
              limit_type
              kor_name
              version
            }
            provideDataService {
              child_service {
                kor_name
                limit_content
                url
              }
            }
            analysisService {
              child_service {
                kor_name
                limit_content
                url
              }
            }
            additionalService {
              child_service {
                kor_name
                limit_content
                url
              }
            }
          }
        }
        userId
        adminRoleList
        adminName
        id
        createdAt # 가입일
        email # 이메일
        emailVerified # 이메일 인증 여부
        nickname # 닉네임
        tel # 휴대폰 번호
        isBusinessAccount # 법인 회원 여부
        userType # 회원 유형
        profilePhoto # 프로필 사진
        interest #관심/전문분야
        introduction # 자기소개
        whichSns
        isSpecialist
        # 연동된 회사 정보
        connectedBusiness {
          id
          createdAt
          updatedAt
          connectedUserList {
            id
            createdAt
            updatedAt
            email
            nickname
            outputRefCode
            inputRefCode
            tel
          }
          businessName
          ceoName
          address
          businessRegisterNo
          businessTel
          businessEmail
          businessType
          representImage
          logo
          representSentence
          link
          businessScale
          businessStartDate
          representContent
          specializedServiceList
          authList
          brochureTitle
          brochurePath
          primaryCountryList
          specializedItem
          majorCustomers
          likeCount
          viewCount
          businessManagerList {
            id
            createdAt
            updatedAt
            position
            task
            name
            phoneNumber
            email
          }
          branchInfoList {
            id
            createdAt
            updatedAt
            name
            tel
            email
            address
          }
        }
        # 법인 계정인 경우 회사 정보
        managedBusiness {
          id
          createdAt
          updatedAt
          auth_status
          promotionSentence
          connectedUserList {
            id
            createdAt
            updatedAt
            email
            nickname
            outputRefCode
            inputRefCode
            tel
            profilePhoto
          }
          businessName
          ceoName
          address
          businessRegisterNo
          business_registration_paper_url
          businessTel
          businessEmail
          businessType
          representImage
          logo
          representSentence
          link
          businessScale
          businessStartDate
          representContent
          specializedServiceList
          authList
          brochureTitle
          brochurePath
          primaryCountryList
          specializedItem
          majorCustomers
          likeCount
          viewCount
          businessManagerList {
            id
            createdAt
            updatedAt
            position
            task
            name
            phoneNumber
            email
          }
          branchInfoList {
            id
            createdAt
            updatedAt
            name
            tel
            email
            address
          }
        }
        larchiveProfile {
          unreadMessageCount
          totalMessageCount
          totalCommentCount
          level
          likedPostAndCommentCountByOthers # 좋아요 총합
          reportPostCountByOthers # 신고수 총합 필요
          likeCompanyList
          scrapPostList {
            id
            createdAt
            postId
            isPinned
          }
          likedPostList {
            id
            createdAt
            postId
          }
          likedCommentList
          writtenPostList {
            id
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_LIKE_LIST = gql`
  query Que_Get_Company_Like_List($input: ShowProfileInputDto) {
    showProfile(input: $input) {
      ok
      error
      user {
        larchiveProfile {
          likeCompanyList
        }
      }
    }
  }
`;

export const GET_CAREER_AND_EDUCATION_LIST = gql`
  query Que_Get_Career_And_Education_List($input: ShowProfileInputDto) {
    showProfile(input: $input) {
      ok
      error
      user {
        id
        introduction
        careerList {
          id
          position
          company
          companyJoinedYear
          companyJoinedMonth
          companyQuittedYear
          companyQuittedMonth
          isCurrentCompany
        }
        educationList {
          id
          major
          school
          admissionYear
          graduationYear
          isCurrentSchool
        }
      }
    }
  }
`;
