import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { retryLazy } from 'util/LazyUtil';
import { Suspense } from 'react';

import MemeberAuthCallback from 'views/AuthCallback';
import HealthCheck from 'health/check';
import ResetPassword from 'views/ResetPassword';

import WebMainLayout from 'components/layouts';
import NotFoundPage from 'NotFound';

import MypageMainLayout from 'views/MyPage/MypageMainLayout';

import LogisticTools from 'views/LogisticTools';

const FreightConditionMonitorV1 = retryLazy(() => import('views/FreightConditionMonitorV1'));
const RiskMonitor = retryLazy(() => import('views/RiskMonitorV1'));
const PortCongestionV3 = retryLazy(() => import('views/PortMonitoring'));

const GlobalSupplyChain = retryLazy(() => import('views/GlobalSupplyChain'));
const Home = retryLazy(() => import('../views/Home'));
const CompanyAd = retryLazy(() => import('../views/CompanyAd'));
const CompanyAdDetail = retryLazy(() => import('../views/CompanyAd/CompanyAdDetail'));

const Plan = retryLazy(() => import('views/Plan'));
const Faq = retryLazy(() => import('views/Faq'));

const Term = retryLazy(() => import('../views/Terms/Term'));
const Privacy = retryLazy(() => import('../views/Terms/Privacy'));
const Partnership = retryLazy(() => import('../views/Partnership'));
const Adinquiry = retryLazy(() => import('../views/Adinquiry'));

const Notice = retryLazy(() => import('../views/Community/Notice'));
const NewsCenter = retryLazy(() => import('../views/Community/NewsCenter'));

const ReferenceRoom = retryLazy(() => import('../views/Community/ReferenceRoom'));

const CargoEstimates = retryLazy(() => import('views/CargoEstimates'));

const MypageMain = retryLazy(() => import('../views/MyPage'));
const MypageDefaultConfig = retryLazy(() => import('../views/MyPage/MypageDefaultConfig'));
const MypageCompanyAbout = retryLazy(() => import('../views/MyPage/MypageCompanyAbout'));

const MypagePayment = retryLazy(() => import('../views/MyPage/MypagePayment'));
const MypageTeamPlan = retryLazy(() => import('../views/MyPage/MypageTeamPlan'));
const MypagePaymentCancel = retryLazy(() => import('../views/MyPage/MypagePayment/MypagePaymentCancel'));
const MypagePaymentRefund = retryLazy(() => import('../views/MyPage/MypagePayment/MypagePaymentRefund'));

const MainRouter = () => {
  return (
    <Router>
      <Switch>
        {/* <Route exact path="/v2/ustruck" component={IframeUsaTruckCongestion} />
        <Route exact path="/v2/usintermodal" component={IframeUsaTerminalCongested} />
        <Route exact path="/v2/uswestterminal" component={IframeWestUsaTerminalCongestion} /> */}
        <Route exact path="/auth-callback" component={MemeberAuthCallback} />
        <Route path="/health/check" component={HealthCheck} />
        <Route path="/reset-password" component={ResetPassword} />
        <WebMainLayout>
          <Switch>
            <Suspense fallback={<></>}>
              <Route exact path="/" component={Home} />

              <Route path="/freight-condition" component={FreightConditionMonitorV1} />

              <Route path="/riskmonitor" component={RiskMonitor} />

              <Route path="/portmonitoring" component={PortCongestionV3} />

              <Route exact path="/global-supply-chain" component={GlobalSupplyChain} />

              <Route path="/logistic-tools" component={LogisticTools} />

              <Route path="/terms/term" component={Term} />

              <Route path="/terms/privacy" component={Privacy} />

              <Route path="/partnership" component={Partnership} />

              <Route path="/ad-inquiry" component={Adinquiry} />

              <Route path="/plan" component={Plan} />

              <Route path="/faq" component={Faq} />

              <Route exact path="/company-ad" component={CompanyAd} />

              <Route path="/company-ad/:id" component={CompanyAdDetail} />

              <Route path="/community/notice" component={Notice} />

              <Route path="/community/news-center" component={NewsCenter} />

              <Route path="/community/reference-room" component={ReferenceRoom} />

              {/* <Route path="/ranking/fame" component={Fame} /> */}

              {/* <Route path="/ranking/knowledge" component={Knowledge} /> */}

              <Route exact path="/warehouse_quote" component={CargoEstimates} />

              <Route
                exact
                path={'/mypage'}
                // render={() => (
                //   <MypageMainLayout>
                //     <MypageMain />
                //   </MypageMainLayout>
                // )}
              >
                {
                  <MypageMainLayout>
                    <MypageMain />
                  </MypageMainLayout>
                }
              </Route>
              <Route
                exact
                path={'/mypage/default/:id'}
                render={() => (
                  <MypageMainLayout>
                    <MypageDefaultConfig />
                  </MypageMainLayout>
                )}
              />
              <Route
                exact
                path={'/mypage/payment/:id'}
                render={() => (
                  <MypageMainLayout>
                    <MypagePayment />
                  </MypageMainLayout>
                )}
              ></Route>

              <Route
                exact
                path={'/mypage/teamplan/:id'}
                render={() => (
                  <MypageMainLayout>
                    <MypageTeamPlan />
                  </MypageMainLayout>
                )}
              ></Route>

              <Route
                exact
                path={'/mypage/company/about'}
                render={() => (
                  <MypageMainLayout>
                    <MypageCompanyAbout />
                  </MypageMainLayout>
                )}
              />

              {/* <Route
                exact
                path={'/mypage/message'}
                render={() => (
                  <MypageMainLayout>
                    <MypageMessage />
                  </MypageMainLayout>
                )}
              /> */}

              <Route exact path={'/payment/cancel'}>
                <MypagePaymentCancel />
              </Route>
              <Route exact path={'/payment/refund'}>
                <MypagePaymentRefund />
              </Route>
            </Suspense>

            <Route path="*" component={NotFoundPage} />
          </Switch>
        </WebMainLayout>
      </Switch>
    </Router>
  );
};

export default MainRouter;
