import baseStyled, { ThemedStyledInterface } from 'styled-components';

import { DefaultTheme } from 'styled-components';

// ! deprecated 이전 theme 변경 완료시 삭제 처리
// 공통 컬러
const colors = {
  // * main base green color
  baseColor: '#40b882',
  // * main white background color
  backgroundColor: '#ffffff',
  // * header background color
  headerBacgroundColor: '#2f2f35',
  // * table header border
  tableHeadBorder: '#696969',
  tableHeadBack: '#f8f8f8',
  borderColor: '#E8E8E8',
  mainFontColor: '#000',
  subFontColor: '#808080',
  infoFontColor: '#888',
  placeHolderColor: '#ddd',
  likePostCount: '#ED5133',
  // * buttons text normal color
  buttontextNavyColor: '#456580',
  // * buttons normal background color
  buttonBackgroundColor: '#f1f1f1',
  // * foucs color
  foucsColor: '#83ecab',
  redColor: '#E84C38',
  blueColor: '#2864FF',
  yellowColor: '#F9BE28',
  purpleColor: '#8F4BCD',
  subBackgroundColor: '#EFFBF5',
  grayBackgroundColor: '#F6F6F6',
  testgreenColor: '#31C9AE',
  riskDetectGreen: '#4fdc89',
  newBaseBlueColor: '#2136E8',
  newSoftBlueColor: '#5c6aea',
};

const whiteTheme = {
  primary: {
    base: '#46B67F',
    font: '#32344D',
    background: '#ffffff',
    buttonSelectBg: '#132D46',
    border: '#E8E8E8',
    radius: '8px',
    boxBackground: '#F5F7FE',
    tableHeader: '#6A6E79',
    subFont: '#6A6E79',
  },
};

const dashboardColors = {
  base: '#56D57C',
  point: '#39CB65',
  mainFont: '#454545',
  subFont: '#848484',
  border: '#e6e6e6',
  white: '#fff',
  black: '#333',
  blue: '#4770FF',
  red: '#FF4747',
  // navy: '#456580',
  greenBackground: '#F7FFF8',
  grayBackground: '#F7F7F7',
  blueBackground: '#F5F8FF',
  redBackground: '#FFF4F4',
};

const size = {
  baseTitle: '48px',
};

const desktopWidth = {
  width: '1200px',
};
const boxShadow = {
  baseShadow: 'rgb(0 0 0 / 16%) 0px 1px 3px 0px',
};

const renewalShadow = {
  baseShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 20px',
  boardListBoxShadow: 'rgb(0 0 0 / 20%) 0px 2px 5px 0px',
};
const theme = {
  colors,
  desktopWidth,
  size,
  boxShadow,
  dashboardColors,
  renewalShadow,
  whiteTheme,
};

//! 신규 theme

export type ThemeConfigType = 'light' | 'dark';

const GeneralTheme = {
  Font: {
    xx_large: '32px',
    x_large: '24px',
    large: '20px',
    medium: '17px',
    regular: '16px',
    small: '15px',
    x_small: '14px',
    xx_small: '13px',
  },
  FontWegiht: {
    light: '400',
    medium: '500',
    bold: '600',
    extra_bold: '700',
  },
  Shadow: {
    shadow_main: '0px 4px 14px 0px rgba(0, 0, 0, 0.1)',
  },

  Animations: {
    fast: '200ms',
    normal: '300ms',
    slow: '500ms',
    easing: 'ease-in-out',
  },
  BtnHeight: {
    tab_lg: '56px',
    tab_md: '52px',
    tab_sm: '46px',
    button_lg: '40px',
    button_md: '30px',
    buttom_link: '37px',
  },
  ZIndex: {
    base: 0,
    header: 1000,
    modal: 200,
    dropdown: 300,
    tooltip: 400,
    overlay: 500,
    loading: 600,
  },

  ...theme,

  // - 테스트용이라 아래는 나중에 삭제
  V2_primary: {
    base: '#46B67F',
    font: '#32344D',
    background: '#ffffff',
    buttonSelectBg: '#132D46',
    border: '#E8E8E8',
    radius: '8px',
    boxBackground: '#F5F7FE',
    tableHeader: '#6A6E79',
    subFont: '#6A6E79',
  },
} as const;

export const defaultTheme: DefaultTheme = {
  ...GeneralTheme,
  Primary: {
    base: '#46B67F',
    font_main: '#202136',
    font_primary: '#132D46',
    font_secondary: '#32344D',
    font_tertiary: '#6A6E79',
    font_quaternary: '#B3B3B3',
    font_quinary: '#F5F7FE',
    font_senary: '#00A554',
    font_select_inverse: '#ffffff',
    font_red_shade0: '#E91145',
    font_blue_shade0: '#1D3CD8',
    bg_main: '#F5F7FE',
    bg_primary: '#ffffff',
    bg_secondary: '#F5F7FE',
    bg_quaternary: '#F0F2FA',
    bg_quinary: '#F4F4F4',
    bg_senary: '#D6FFEA',
    bg_darkmodeBtn: '#E0FFE0',
    bg_tab_select: '#132D46',
    bg_gray_shade0: '#FCFCFC',
    bg_red_shade0: '#FFEAEF',
    bg_blue_shade0: '#F5F7FE',
    border_primary: '#E8E8E8',
    border_secondary: '#B3B3B3',
    border_tertiary: '#6A6E79',
    border_focus: '#00D4AB',
    border_quinary: '#E8E8E8',
    border_quaternary: '#DCDCDC',
    hover_bg: '#E5FFF2',
    hover_gray: '#F5F7FE',
    line_main: '#E8E8E8',
    letter_spacing: '-0.32px',
  },
  Components: {
    RiskMonitor: {
      Overview: {
        bg_select_tab: '#132D46',
        font_primary: '#132D46',
        font_secondary: '#6A6E79',
        font_tertiary: '#32344D',
        font_quaternary: '#B3B3B3',

        font_select: '#fff',
        bg_primary: '#fff',
        bg_select: '#132D46',
        bg_globalanalysis: '#F5F7FE',
        border_map_unselect_btn: '2px solid #fff',
        border_map_select_btn: '2px solid #46B67F',
      },
    },
    RiskMonitoring: {
      Tracking: {
        tag: {
          default_text_color: '#6A6E79',
          default_border_color: '#E8E8E8',
          default_bg_color: '#FFFFFF',
          selected_text_color: '#6A6E79',
          selected_border_color: '#E8E8E8',
          selected_bg_color: '#132D46',
        },
      },
    },
    Button: {
      UnderBarTab: {
        default_text_color: '#6A6E79',
        default_border_color: '#E8E8E8',
        default_bg_color: '#FFF',
        selected_text_color: '#132D46',
        selected_border_color: '#00D4AB',
        selected_bg_color: '#FFFFFF',
      },
      MainTab: {
        default_text_color: '#32344D',
        default_border_color: '#E8E8E8',
        default_bg_color: '#FFFFFF',
        selected_text_color: '#FFFFFF',
        selected_border_color: '#132D46',
        selected_bg_color: '#132D46',
      },
      SquareTab: {
        default_text_color: '#6A6E79',
        default_border_color: '#E8E8E8',
        default_bg_color: '#F5F7FE',
        selected_text_color: '#000',
        selected_border_color: '#46B67F',
        selected_bg_color: '#E5FFF2',
      },
      SquareMiniTab: {
        default_text_color: '#6A6E79',
        default_border_color: '#E8E8E8',
        default_bg_color: '#F5F7FE',

        selected_text_color: '#FFFFFF',
        selected_border_color: '#46B67F',
        selected_bg_color: '#46B67F',
      },
    },
    Navigation: {
      font_main: '#32344D',
      font_disable: '#D4D3D3',
      font_plan_btn: '#46B67F',
      font_btn: '#fff',
      bg_main: '#fff',
      bg_plan_btn: '#132D46',
      bg_login_btn: '#46B67F',
      sub_side_nav_bg: '#E6FBF7',
    },
    LogisticNav: {
      font_main: '#32344D',
      border_main: '#DCDCDC',
      icon_fill_color: '#6A6E79',
      icon_current_fill_color: '#46B67F',
      bg_main: '#fff',
    },
    Footer: {
      font_main: '#1A1E29',
      font_sub: '#6A6E79',
      divide: '#D1D5DB',
    },
  },
};

export const darkTheme: DefaultTheme = {
  ...GeneralTheme,
  Primary: {
    base: '#46B67F',
    font_main: '#ffffff',
    font_primary: '#132D46',
    font_secondary: '#32344D',
    font_tertiary: '#6A6E79',
    font_quaternary: '#B3B3B3',
    font_quinary: '#F5F7FE',
    font_senary: '#00A554',
    font_select_inverse: '#ffffff',
    font_red_shade0: '#E91145',
    font_blue_shade0: '#1D3CD8',
    bg_primary: '#1A1E29',
    bg_main: '#1E2331',
    bg_secondary: '#F5F7FE',
    bg_quaternary: '#F0F2FA',
    bg_quinary: '#F4F4F4',
    bg_senary: '#D6FFEA',
    bg_darkmodeBtn: '#555555',
    bg_tab_select: '#132D46',
    bg_gray_shade0: '#FCFCFC',
    bg_red_shade0: '#FFEAEF',
    bg_blue_shade0: '#F5F7FE',
    line_main: '#32344D',
    letter_spacing: '-0.32px',
    border_primary: '#32344D',
    border_secondary: '#B3B3B3',
    border_tertiary: '#6A6E79',
    border_quinary: '#E8E8E8',
    border_quaternary: '#DCDCDC',
    border_focus: '#32344D',
    hover_bg: '#32344D',
    hover_gray: '#F5F7FE',
  },
  Components: {
    RiskMonitor: {
      Overview: {
        bg_select_tab: '#132D46',
        font_primary: '#132D46',
        font_secondary: '#6A6E79',
        font_tertiary: '#32344D',
        font_quaternary: '#B3B3B3',
        font_select: '#fff',
        bg_primary: '#fff',
        bg_select: '#132D46',
        bg_globalanalysis: '#F5F7FE',
        border_map_unselect_btn: '2px solid #fff',
        border_map_select_btn: '2px solid #46B67F',
      },
    },
    RiskMonitoring: {
      Tracking: {
        tag: {
          default_text_color: '#B3B3B3',
          default_border_color: '#E8E8E8',
          default_bg_color: '#1E2331',
          selected_text_color: '#FFF',
          selected_border_color: '#E8E8E8',
          selected_bg_color: '#1A1E29',
        },
      },
    },
    Button: {
      UnderBarTab: {
        default_text_color: '#B3B3B3',
        default_border_color: '#32344D',
        default_bg_color: '#272C3B',
        selected_text_color: '#FFFFFF',
        selected_border_color: '#00D4AB',
        selected_bg_color: '#272C3B',
      },
      MainTab: {
        default_text_color: '#B3B3B3',
        default_border_color: '#32344D',
        default_bg_color: '#1E2331',
        selected_text_color: '#FFFFFF',
        selected_border_color: '#46B67F',
        selected_bg_color: '#46B67F',
      },
      SquareTab: {
        default_text_color: '#B3B3B3',
        default_border_color: '#32344D',
        default_bg_color: '#272C3B',
        selected_text_color: '#FFFFFF',
        selected_border_color: '#46B67F',
        selected_bg_color: '#253441',
      },
      SquareMiniTab: {
        default_text_color: '#FFFFFF',
        default_border_color: '#6A6E79',
        default_bg_color: '#6A6E79',

        selected_text_color: '#FFFFFF',
        selected_border_color: '#46B67F',
        selected_bg_color: '#46B67F',
      },
    },
    Navigation: {
      font_plan_btn: '#fff',
      font_main: '#fff',
      font_disable: '#D4D3D3',
      font_btn: '#fff',
      bg_plan_btn: '#46B67F',
      bg_main: '#1A1E29',
      bg_login_btn: '#46B67F',
      sub_side_nav_bg: '#1E2331',
    },

    LogisticNav: {
      border_main: '#32344D',
      icon_fill_color: '#6A6E79',
      icon_current_fill_color: '#46B67F',
      bg_main: '#1A1E29',
      font_main: '#fff',
    },
    Footer: {
      font_main: '#ffffff',
      font_sub: '#6A6E79',
      divide: '#D1D5DB',
    },
  },
};

export type Theme = DefaultTheme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
