import {
  CalculatorIcon,
  CongestionIcon,
  ContainerIcon,
  ConveniencelIcon,
  DefaultHomeIcon,
  EconomyIcon,
  MydashboardIcon,
  RawMaterialIcon,
  // ScheduleIcon,
  AirInlandIcon,
} from 'assets/images';

import { BiNums } from '__apollo__generated__/globalTypes';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';

type CategoryIconProps = {
  iscurrent?: string;
};

const CalculatorIconComponents = styled(CalculatorIcon)<CategoryIconProps>`
  fill: ${({ theme, iscurrent }) =>
    iscurrent ? theme.Components.LogisticNav.icon_current_fill_color : theme.Components.LogisticNav.icon_fill_color};
`;

const CongestionIconComponents = styled(CongestionIcon)<CategoryIconProps>`
  /* fill: ${({ theme }) => theme.Components.LogisticNav.icon_fill_color}; */
  fill: ${({ theme, iscurrent }) =>
    iscurrent ? theme.Components.LogisticNav.icon_current_fill_color : theme.Components.LogisticNav.icon_fill_color};
`;
export const ContainerIconComponents = styled(ContainerIcon)<CategoryIconProps>`
  /* fill: ${({ theme }) => theme.Components.LogisticNav.icon_fill_color};
   */
  fill: ${({ theme, iscurrent }) =>
    iscurrent ? theme.Components.LogisticNav.icon_current_fill_color : theme.Components.LogisticNav.icon_fill_color};
`;
const ConveniencelIconComponents = styled(ConveniencelIcon)<CategoryIconProps>`
  /* fill: ${({ theme }) => theme.Components.LogisticNav.icon_fill_color}; */
  fill: ${({ theme, iscurrent }) =>
    iscurrent ? theme.Components.LogisticNav.icon_current_fill_color : theme.Components.LogisticNav.icon_fill_color};
`;
const DefaultHomeIconComponents = styled(DefaultHomeIcon)<CategoryIconProps>`
  /* fill: ${({ theme }) => theme.Components.LogisticNav.icon_fill_color}; */
  fill: ${({ theme, iscurrent }) =>
    iscurrent ? theme.Components.LogisticNav.icon_current_fill_color : theme.Components.LogisticNav.icon_fill_color};
`;
const MydashboardIconComponents = styled(MydashboardIcon)<CategoryIconProps>`
  /* fill: ${({ theme }) => theme.Components.LogisticNav.icon_fill_color}; */
  fill: ${({ theme, iscurrent }) =>
    iscurrent ? theme.Components.LogisticNav.icon_current_fill_color : theme.Components.LogisticNav.icon_fill_color};
`;
const RawMaterialIconComponents = styled(RawMaterialIcon)<CategoryIconProps>`
  /* fill: ${({ theme }) => theme.Components.LogisticNav.icon_fill_color}; */
  fill: ${({ theme, iscurrent }) =>
    iscurrent ? theme.Components.LogisticNav.icon_current_fill_color : theme.Components.LogisticNav.icon_fill_color};
`;
// const ScheduleIconComponents = styled(ScheduleIcon)<CategoryIconProps>`
//   /* fill: ${({ theme }) => theme.Components.LogisticNav.icon_fill_color}; */
//   fill: ${({ theme, iscurrent }) =>
//     iscurrent ? theme.Components.LogisticNav.icon_current_fill_color : theme.Components.LogisticNav.icon_fill_color};
// `;
const AirInlandIconComponents = styled(AirInlandIcon)<CategoryIconProps>`
  /* fill: ${({ theme }) => theme.Components.LogisticNav.icon_fill_color}; */
  fill: ${({ theme, iscurrent }) =>
    iscurrent ? theme.Components.LogisticNav.icon_current_fill_color : theme.Components.LogisticNav.icon_fill_color};
`;
const EconomyIconComponents = styled(EconomyIcon)<CategoryIconProps>`
  /* fill: ${({ theme }) => theme.Components.LogisticNav.icon_fill_color}; */
  fill: ${({ theme, iscurrent }) =>
    iscurrent ? theme.Components.LogisticNav.icon_current_fill_color : theme.Components.LogisticNav.icon_fill_color};
`;

export interface IndexDataType {
  id: BiNums;
  category?: string;
  indexName: string;
  figure: number | null;
  variance: number | null;
  percent: number | null;
  updated: string;
}

/* NAVIGATION MENU */
interface NavMenuListType {
  title: string;
  icon: StyledComponent<
    React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string;
      }
    >,
    DefaultTheme,
    CategoryIconProps,
    never
  >;
  url?: string;
  gap?: boolean;
  newFeatureDate?: string;
  comingSoon?: boolean;
  isBeta?: boolean;
  submenuList?: SubmenuListType[];
}

interface SubmenuListType {
  title: string;
  url: string;
  newFeatureDate?: string;
}

export const navMenuList: NavMenuListType[] = [
  {
    title: '홈',
    icon: DefaultHomeIconComponents,

    url: '/logistic-tools/main',
  },
  {
    title: '마이 대시보드',
    icon: MydashboardIconComponents,
    url: '/logistic-tools/my-dashboard/bianalyze/composite',
    isBeta: true,
    gap: true,
  },
  // {
  //   title: '스케줄 조회',
  //   icon: ScheduleIconComponents,
  //   url: '/logistic-tools/schedule',
  //   submenuList: [
  //     // todo: 우선 가림처리
  //     // {
  //     //   title: 'FCL 해상스케줄',
  //     //   url: '/logistic-tools/schedule/sea/fcl',
  //     // },
  //     {
  //       title: '컨테이너 터미널 스케줄',
  //       url: '/logistic-tools/schedule/container-terminal',
  //     },
  //   ],
  // },
  {
    title: '해운지표',
    icon: ContainerIconComponents,
    newFeatureDate: '2024-11-25',
    url: '/logistic-tools/sea-index/container-rate-index',
    submenuList: [
      {
        title: '컨테이너 운임지수',
        url: '/logistic-tools/sea-index/container-rate-index/composite',
        newFeatureDate: '2025-03-20',
      },
      {
        title: '컨테이너 용선지수',
        url: '/logistic-tools/sea-index/container-charter-rate-index/hrci',
      },
      {
        title: '컨테이너 공급지표',
        url: '/logistic-tools/sea-index/container-supply-index/deliverydate',
      },

      {
        title: '벌크운임지수',
        url: '/logistic-tools/sea-index/bulk-index/bfi',
        newFeatureDate: '2025-03-20',
      },
      {
        title: '탱커운임지수',
        url: '/logistic-tools/sea-index/tanker-index/tanker-world-scale',
      },
      {
        title: '선사 선복량',
        url: '/logistic-tools/sea-index/shipping-capacity/all',
      },
      {
        title: '블랭크 세일링',
        url: '/logistic-tools/sea-index/blank-sailings',
      },
      {
        title: '해운정시성 지표',
        url: '/logistic-tools/sea-index/schedule-reliability/global-schedule-reliability',
      },
      {
        title: '선박연료유',
        url: '/logistic-tools/sea-index/bunkering/average',
      },
      {
        title: '주요 노선별 공급현황',
        url: '/logistic-tools/sea-index/sctr',
        newFeatureDate: '2025-03-24',
      },
      {
        title: '선가지수',
        url: '/logistic-tools/sea-index/ship-price-index/nsb',
      },
      {
        title: '미주 항만 컨 물동량',
        url: '/logistic-tools/sea-index/mupv',
      },
      {
        title: '권역별물동량(PIERS)',
        url: '/logistic-tools/sea-index/regional-con-volume/pptv',
        newFeatureDate: '2025-03-20',
      },
      {
        title: '권역별물동량(CTS)',
        url: '/logistic-tools/sea-index/ocean-teu/global',
      },
      {
        title: '컨테이너 항만 물동량 지수',
        url: '/logistic-tools/sea-index/gcpti',
        newFeatureDate: '2025-03-20',
      },
    ],
  },
  {
    title: '운송지연',
    url: '/logistic-tools/congestion-in-logistics',
    icon: CongestionIconComponents,
    submenuList: [
      {
        title: '글로벌 해상 초크포인트 모니터링',
        url: '/logistic-tools/congestion-in-logistics/globalchokepoint',
      },
      {
        title: '미주 내륙운송 혼잡도',
        url: '/logistic-tools/congestion-in-logistics/usa-truck-congestion/inbound',
      },
      {
        title: '미주 철도터미널 혼잡도',
        url: '/logistic-tools/congestion-in-logistics/us-intermodal-terminal-congested/rtdt_week',
      },
      {
        title: '미주 서안 터미널 혼잡도',
        url: '/logistic-tools/congestion-in-logistics/west-usa-terminal-congestion/average-dwell-time-in-days',
      },
      {
        title: '선박위치 추적',
        url: '/logistic-tools/congestion-in-logistics/ship-tracking',
      },
      {
        title: '세계기상정보',
        url: '/logistic-tools/congestion-in-logistics/world-weather-map',
      },
    ],
  },
  {
    title: '항공/내륙지표',
    icon: AirInlandIconComponents,
    newFeatureDate: '2024-12-02',
    url: '/logistic-tools/air-inland-index/',
    submenuList: [
      {
        title: '항공운임지수',
        url: '/logistic-tools/air-inland-index/air-rate-index/tac',
      },
      {
        title: 'FS/SC 조회',
        url: '/logistic-tools/air-inland-index/fssc',
      },
      {
        title: 'IATA 항공유',
        url: '/logistic-tools/air-inland-index/jfpm',
      },
      {
        title: 'IATA 공급/수요지표(화물)',
        url: '/logistic-tools/air-inland-index/iata/composite',
      },
      {
        title: 'IATA 공급/수요지표(여객)',
        url: '/logistic-tools/air-inland-index/iatap/composite',
      },
      {
        title: 'WorldACD 항공지표',
        url: '/logistic-tools/air-inland-index/wacd',
        newFeatureDate: '2025-03-24',
      },
      {
        title: 'OAG 항공지표',
        url: '/logistic-tools/air-inland-index/oagc/global-total-seats',
      },
      {
        title: 'AAPA 항공지표',
        url: '/logistic-tools/air-inland-index/aapa',
      },
      {
        title: '미주창고운임지수',
        url: '/logistic-tools/air-inland-index/uswr',
        newFeatureDate: '2025-03-20',
      },
      {
        title: '철송운임지수',
        url: '/logistic-tools/air-inland-index/rfri',
        newFeatureDate: '2025-03-20',
      },
      {
        title: '미주트럭운임지수',
        url: '/logistic-tools/air-inland-index/usa-truck-rate-index/tvi',
      },
      {
        title: '미주 가솔린/디젤가격',
        url: '/logistic-tools/air-inland-index/usa-gasoline-diesel/gasoline',
      },
      {
        title: '미주 인터모달 운임지수',
        url: '/logistic-tools/air-inland-index/isr',
      },
    ],
  },
  {
    title: '경제지표',
    icon: EconomyIconComponents,
    url: '/logistic-tools/other-index',
    submenuList: [
      {
        title: 'EU 탄소배출권',
        url: '/logistic-tools/other-index/eu-eua',
      },

      {
        title: '구매관리자지수(PMI)',
        url: '/logistic-tools/other-index/pmi-index/composite',
      },
      {
        title: '미국 물류관리자지수(LMI)',
        url: '/logistic-tools/other-index/lmi-index/overview',
      },
      {
        title: '글로벌 공급망 압력지수',
        url: '/logistic-tools/other-index/gscpi',
      },
      {
        title: '블랙록 지정학적 리스크 지수',
        url: '/logistic-tools/other-index/blackrock',
      },
      {
        title: '글로벌 공급망 변동성지수',
        url: '/logistic-tools/other-index/gep',
        newFeatureDate: '2025-03-20',
      },
      {
        title: 'Fed 지정학 리스크 지수',
        url: '/logistic-tools/other-index/fedgpr/composite',
      },
      {
        title: '글로벌 물류산업 업황지수',
        url: '/logistic-tools/other-index/gli',
        newFeatureDate: '2025-03-24',
      },
      {
        title: '글로벌 물류부동산 업황지수',
        url: '/logistic-tools/other-index/lri',
        newFeatureDate: '2025-03-24',
      },
    ],
  },
  {
    title: '원자재지표',
    icon: RawMaterialIconComponents,
    url: '/logistic-tools/raw-materials-index',
    submenuList: [
      {
        title: '국제유가지수',
        url: '/logistic-tools/raw-materials-index/oil-index',
      },
      {
        title: '세계광물가격',
        url: '/logistic-tools/raw-materials-index/global-mineral-prices/overview',
      },
      {
        title: '세계식량가격',
        url: '/logistic-tools/raw-materials-index/fao/overview',
      },
    ],
  },

  {
    title: '편의기능',
    icon: ConveniencelIconComponents,
    url: '/logistic-tools/business-support/',
    submenuList: [
      {
        title: '국가별 공휴일',
        url: '/logistic-tools/business-support/national-holiday',
      },
      {
        title: '화물안전운임제',
        url: '/logistic-tools/business-support/inland-tariff-rate',
      },
      {
        title: '차량제원',
        url: '/logistic-tools/business-support/vehicle-spec',
      },
      {
        title: '물류시설찾기',
        url: '/logistic-tools/business-support/logistics-facillty-list',
      },
      {
        title: '관세고시환율',
        url: '/logistic-tools/business-support/customs-exchange-rate',
      },
      // {
      //   title: '주요 국가별 환율조회',
      //   url: '/logistic-tools/business-support/country-exchange-rate',
      // },
      {
        title: '수입화물 통관조회',
        url: '/logistic-tools/business-support/import-cargo-clearance',
      },
    ],
  },
  {
    title: '계산기',
    icon: CalculatorIconComponents,
    url: 'logistic-tools/calculator',
    submenuList: [
      {
        title: 'CBM 계산기',
        url: '/logistic-tools/calculator/cbm-calculator',
      },
      {
        title: '관부가세 계산기(일반)',
        url: '/logistic-tools/calculator/duty-calculator',
      },
    ],
  },
];
