import { DefaultColorLogo } from 'assets/image';
import LazyImage from 'components/common/LazyImage';
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
  return (
    <section className="flex flex-col items-center justify-start w-screen h-screen pt-[300px] relative">
      <div className="-ml-5 ">
        <LazyImage src={DefaultColorLogo} alt={'larchive-logo'} height={80} />
      </div>
      <h1 className="text-[48px] text-slate-600 font-bold mt-10">404</h1>
      <div className="mt-10">
        <div className="flex items-center flex-col gap-4 text-[18px] text-slate-500 font-semibold ">
          <div>페이지가 존재하지 않습니다.</div>
          <div className="flex flex-col items-center gap-4">
            <div>페이지의 주소가 변경되었거나 삭제되었을 수 있습니다.</div>
            <div>
              <span className="underline text-main-color underline-offset-4">help@simplogis.com</span> 으로 문의
              바랍니다.
            </div>
            <Link className="text-baseColor" to={'/'}>
              메인 페이지로 이동하기
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
