import NotFoundPage from 'NotFound';
import { Route, Switch } from 'react-router-dom';

import { retryLazy } from 'util/LazyUtil';
import LogisticToolsContainerAdaptiveLayout from 'views/LogisticTools/Components/layouts';

const FCL = retryLazy(() => import('views/LogisticTools/Schedule/FCL'));
const ContainerTerminal = retryLazy(() => import('views/LogisticTools/Schedule/ContainerTerminal'));

const SeaIndex = retryLazy(() => import('views/LogisticTools/SeaIndex'));

const AirlandIndex = retryLazy(() => import('views/LogisticTools/AirInlandIndex'));

const OtherIndex = retryLazy(() => import('views/LogisticTools/OtherIndex'));

const RawMaterialsIndex = retryLazy(() => import('views/LogisticTools/RawMaterialsIndex'));

const CongestionLogistics = retryLazy(() => import('views/LogisticTools/CongestionLogistics'));

const BusinessSupport = retryLazy(() => import('views/LogisticTools/BusinessSupport'));

// const WordExchangeRate = retryLazy(() => import('views/LogisticTools/BusinessSupport/CountryExchangeRate'));

// * calculator
const DutyCalculator = retryLazy(() => import('views/LogisticTools/Calculator/DutyCalculator'));
const CbmCalculator = retryLazy(() => import('views/LogisticTools/Calculator/CbmCalculator'));

// * mydashboard
const MyDashboardV2 = retryLazy(() => import('views/LogisticTools/MyDashboardV2'));

// const MyDashboardV1 = retryLazy(() => import('views/LogisticTools/MyDashboardV1'));

const LogisticDashboard = retryLazy(() => import('views/LogisticTools/LogisticDashBoard'));

export const LOGISTIC_ROUTE_LIST = [
  { path: '/logistic-tools/main', component: LogisticDashboard, description: '메인 대시보드', exact: true },

  { path: '/logistic-tools/my-dashboard', component: MyDashboardV2, description: '마이 대시보드' },
  { path: '/logistic-tools/schedule/sea/fcl', component: FCL },
  { path: '/logistic-tools/schedule/container-terminal', component: ContainerTerminal },
  // { path: '/logistic-tools/schedule/airline-schedule', component: AirRateIndex },
  { path: '/logistic-tools/sea-index', component: SeaIndex },
  { path: '/logistic-tools/congestion-in-logistics', component: CongestionLogistics },
  { path: '/logistic-tools/air-inland-index', component: AirlandIndex },
  { path: '/logistic-tools/other-index', component: OtherIndex },
  { path: '/logistic-tools/raw-materials-index', component: RawMaterialsIndex },
  { path: '/logistic-tools/business-support', component: BusinessSupport },

  { path: '/logistic-tools/calculator/cbm-calculator', component: CbmCalculator, exact: true },
  { path: '/logistic-tools/calculator/duty-calculator', component: DutyCalculator, exact: true },
];

export default function LogisticTools() {
  return (
    <>
      <Switch>
        {LOGISTIC_ROUTE_LIST.map((route, idx) => (
          <Route
            exact={route?.exact}
            key={`${route.path}_${idx}`}
            path={route.path}
            render={props => {
              return (
                <LogisticToolsContainerAdaptiveLayout {...props}>
                  <route.component />
                </LogisticToolsContainerAdaptiveLayout>
              );
            }}
          />
        ))}
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </>
  );
}
